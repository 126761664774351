/* You can add global styles to this file, and also import other style files */
.sidebar .nav .nav-item > .nav-link.active i,
.sidebar .nav .nav-item > .nav-link.active .menu-title,
.sidebar .nav .nav-item > .nav-link.active .menu-arrow {
  color: var(--secondary);
}
:root {
  --primary: #6f5e9c;
  --secondary: #ff00a0;
}
.mat-tab-header,
.mat-mdc-tab-header {
  .mat-tab-labels,
  .mat-mdc-tab-labels {
    justify-content: unset;
    .mat-mdc-tab {
      border-radius: var(--border-radius);
      &.mdc-tab--active {
        background: var(--primary);
        border-bottom: 2px solid var(--secondary) !important;
        .mdc-tab__text-label {
          color: #fff;
        }
      }
    }
  }
}
mat-tab-group span.mdc-tab-indicator__content.mdc-tab-indicator__content--underline {
  display: none;
}

textarea {
  min-height: 100px !important;
}  
.form-control:read-only,
.asColorPicker-input:read-only,
.dataTables_wrapper select:read-only,
.select2-container--default .select2-selection--single:read-only,
.select2-container--default .select2-selection--single .select2-search__field:read-only,
.typeahead:read-only,
.tt-query:read-only,
.tt-hint:read-only {
  background-color: inherit;
}
.form-control.disabled, .form-control:disabled, .form-control[readonly], .form-control input:disabled, .form-control input[readonly] {
  background-color: var(--disabled-input-bg);
}